import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';
import { Photo } from '@/composables/usePhotoGallery';

export default {
  async getUserBookings() {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getUserBookings')({ })).data;
  },
  async cancelBooking(bookingId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-cancelBooking')({ bookingId })).data;
  },
  async updateBooking() {
    return true; // TODO
  },

  async createNewBooking(booking: any, photos: Photo[]) {
    const photoLinks = []; // image URL
    if (photos) {
      for (const photo of photos) {
        const link = await uploadImage(photo.base64Data, booking.customerName);
        photoLinks.push(link);
      }
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-createNewBooking')({ booking, photoLinks })).data;
  },

  async settlePayment(booking: any, order: any) {
    if (order.signatureDataURL) { // client signed
      const link = await uploadImage(order.signatureDataURL, booking.customerName);
      order.clientSignatureLink = link;
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-settlePayment')({ booking, order })).data;
  }
}