import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _withDirectives(_createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "projects",
                href: "/projects"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.home }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "profile",
                href: "/profile"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: $setup.personCircle }, null, 8, ["icon"]),
                  ($setup.userLoggedIn)
                    ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('profile')), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('LoginPage.login')), 1)
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, !$setup.hideTabs]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}