import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/pages/LoginPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/projects'
      },
      {
        path: 'home',
        redirect: '/projects'
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'privacy-policy',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'projects/:id?',
        name: 'ProjectListPage',
        component: () => import('@/pages/project/ProjectListPage.vue'),
      },
      {
        path: 'ol/:projectId?/:workLocationType?/:workLocationId?/:workPhotoRecordId?',
        name: 'ProjectOLPage',
        component: () => import("@/pages/project/ProjectOLPage.vue"),
        //props: true,
      },
    ]
  }
]