import BookingService from '@/services/BookingService';
import ProjectService from '@/services/ProjectService';
import UserService from '@/services/UserService';

export const getAllProjects = ({ commit }) => {
  ProjectService.getAllProjects().then(res => {
    commit('upsertProjects', res);
  });
}
export const fetchProjectDetails = ({ commit }, { id, e }) => {
  ProjectService.getProjectById(id).then(res => {
    if (res) {
      res.fetchedDetails = true;
      commit('upsertProjects', [res]);
    }
    if (e) e.target.complete();
  });
}

export const getUserData = ({ commit, dispatch }, { firebaseUser, i18n, disableLoadingBooking }) => {
  commit('setLoadingUserData', true);
  UserService.getUserData().then(res => {
    const { user } = res;
    if (user) {
      if (i18n && user.preferredLang) i18n.global.locale.value = user.preferredLang.toLowerCase(); // update language setting
    } else {
      // user record not exist in DB yet right after registration
      res.user = {
        id: user.uid,
        email: user.email,
        profilePic: "https://ionicframework.com/docs/demos/api/avatar/avatar.svg",
        preferredLang: i18n.global.locale.value,
      };
    }
    commit('upsertProjects', res.projects || []);
    commit('receiveAppUserData', res);
  });

  // retrieve user bookings as well
  //dispatch('getUserBookings', disableLoadingBooking);
}

export const createNewUser = ({ commit }, newUser) => {
  UserService.createNewUser(newUser).then(newUserRow => {
    commit('receiveUser', newUserRow); // this updates projects related to user as well
  });
}
export const resetUserData = ({ state, getters, commit }) => {
  commit('receiveUser', {}); // empty the store user object\
  commit('receiveBookings', []);
}

export const updateUserLastCheckNotificationTime = ({ state, commit }) => {
  state.user.lastCheckNotificationsAt = new Date();
  UserService.updateLastCheckNotificationTime();
}

export const getUserBookings = ({ commit }, disableLoading) => {
  if (!disableLoading) commit('setLoadingBookings', true);
  BookingService.getUserBookings().then(res => {
    commit('receiveBookings', res);
  });
}