import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import { auth, emailSignIn, sendPswResetEmail, customTokenSignin, reauthUserWithCredential, updateUserPassword } from '@/auth';

export default {
  async doLogin(email: string, password: any) {
    return await emailSignIn(email, password);
  },

  async doLogout() {
    return await auth.signOut();
  },

  async resetPassword(email: string) {
    return await sendPswResetEmail(email);
  },

  async updatePassword(oldPassword: any, newPassword: any) {
    try {
      const currentUser: any = auth.currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword);
      await reauthUserWithCredential(currentUser, credential);
      return await updateUserPassword(currentUser, newPassword);
    } catch (err) {
      console.error(err);
      return "wrong-password";
    }
  },

  async doPhoneLogin(phone: any, password: any) {
    const data = (await firebase.app().functions('asia-east2').httpsCallable('user-signInWithPhoneAndPassword')({ phone, password })).data;
    if (data.s == 200) return customTokenSignin(data.t);
    else throw new Error(data.m); // invalid phone / password
  },

  async checkPhoneExists(phone: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-checkPhoneExists')({ phone })).data;
  }
}