import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-372fda0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isEditing ? _ctx.t('userLocation') : _ctx.t('newUserLocation')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { fixed: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('apartmentInformation')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_chip, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.relatedLocation?.name) + " [" + _toDisplayString(_ctx.relatedLocation?.address) + "] ", 1)
                ]),
                _: 1
              })
            ]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.floors.length > 0 && _ctx.flats.length > 0)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select, {
                                fill: "solid",
                                interface: "popover",
                                modelValue: _ctx.userLocation.floor,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userLocation.floor) = $event)),
                                placeholder: _ctx.t('floor'),
                                onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFloorChanged($event.target.value)))
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floors, (floor) => {
                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                      key: floor.code,
                                      value: floor.floor
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(floor.floor) + "樓", 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_select, {
                                fill: "solid",
                                interface: "popover",
                                modelValue: _ctx.userLocation.unit,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userLocation.unit) = $event)),
                                placeholder: _ctx.t('unit')
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flats, (flat) => {
                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                      key: flat,
                                      value: flat
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(flat) + "室", 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                fill: "solid",
                                label: _ctx.t('floor'),
                                "label-placement": "stacked",
                                modelValue: _ctx.userLocation.floor,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userLocation.floor) = $event)),
                                enterkeyhint: "next"
                              }, null, 8, ["label", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                fill: "solid",
                                label: _ctx.t('unit'),
                                "label-placement": "stacked",
                                modelValue: _ctx.userLocation.unit,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userLocation.unit) = $event)),
                                enterkeyhint: "next"
                              }, null, 8, ["label", "modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })),
                  _createVNode(_component_ion_note, {
                    color: "danger",
                    class: "ion-padding"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("b", null, "*" + _toDisplayString(_ctx.t('locationInputWarningMsg')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createBlock(_component_ion_row, {
                  key: 1,
                  class: "ion-margin-top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_spinner)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })),
            _createVNode(_component_ion_row, { class: "ion-padding-top" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_segment, {
                      mode: "ios",
                      modelValue: _ctx.userLocation.role,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userLocation.role) = $event)),
                      scrollable: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_segment_button, { value: "業主" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('業主')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_segment_button, { value: "住戶" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('住戶')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_segment_button, { value: "其他" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('其他')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              color: "success",
              expand: "block",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateUserLocation()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('save')), 1)
              ]),
              _: 1
            }),
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  color: "danger",
                  fill: "clear",
                  expand: "block",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.deleteUserLocation()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('deleteUserLocation')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}