
import { computed, ref, watch } from 'vue';

import { IonPage, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonLabel, IonIcon, IonBadge } from '@ionic/vue';
import { home, people, calendar, newspaper, compass, personCircle, construct, notifications, scan, } from 'ionicons/icons';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useQRCodeScanner } from '@/composables/useQRCodeScanner';

import config from '@/config';

export default {
  name: 'Tabs',
  components: { IonPage, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonLabel, IonIcon, IonBadge, },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const { scanningQRCode } = useQRCodeScanner();

    const hideTabs = ref(false);
    const numOfNewNotifications = computed(() => store.getters.numOfNewNotifications);
    const userLoggedIn = computed(() => store.state.loggedIn);

    const checkHideTabs = (routePath: any) => {
      hideTabs.value = (
        //routePath.split("/").length > 2 || config.authPages.includes(routePath)
        scanningQRCode.value || config.authPages.includes(routePath)
        || config.hideBottomTabsPageNames.includes((route.name || "").toString())
      );
    }
    watch(() => route.path, (newPath) => {
      checkHideTabs(newPath);
    })
    watch(scanningQRCode, () => {
      checkHideTabs(route.path);
    });
    checkHideTabs(route.path); // triggered on first enter APP

    return {
      t,
      
      home, people, calendar, newspaper, compass, personCircle, construct, notifications, scan,

      hideTabs, numOfNewNotifications, scanningQRCode, userLoggedIn,
    }
  }
}
