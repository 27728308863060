import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  // UPDATE
  async updateUserLocation(userLocation: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('location-updateUserLocation')(userLocation);
    return res.data[0]; // return the updated user location
  },

  // DELETE
  async deleteUserLocation(locationId: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('location-deleteUserLocation')({ locationId });
    return res.data[0];
  }
}