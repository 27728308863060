import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      showCloseModal: true,
      title: _ctx.t('loginOrRegister')
    }, null, 8, ["title"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createVNode(_component_login_form, { isFromModal: true })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}