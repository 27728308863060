export default {
  versionCode: '1.0.0',
  websiteUrl: 'https://wins.b-plus.tech',
  
  primaryColor: '#fda619',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo.png'),

  googleMapsApiKey: "AIzaSyBAfbeaXmrlCnW4dICmbieqlX-771Wz_G0",

  Firebase: {
    apiKey: "AIzaSyCckp1JH79bf46NNVOGq3JVv9ZhA68aQ2U",
    authDomain: "bailyhk.firebaseapp.com",
    projectId: "bailyhk",
    storageBucket: "bailyhk.appspot.com",
    messagingSenderId: "200634013505",
    appId: "1:200634013505:web:178edbfecd9c09581533c0",
    measurementId: "G-54XYGT9J87"
  },

  OneSignal: {
    appId: 'e3ab2181-7773-45e3-bea9-fbe8d8cba71e',
  },

  hideBottomTabsPageNames: ["ProjectOLPage", "ProjectDetailPage"],
  authPages: ["/forgot-password", "/login", "/register"],
  //memberPages: ["/checkout", "/profile", "/bookings", "/notifications", "/ol"],
  memberPages: ["/checkout", "/profile", "/bookings", "/notifications"],
  //memberPageNames: ["ProjectOLPage", "ProjectListPage"],
  memberPageNames: ["ProjectListPage"],
}