export default {
  loadingAppPublicData: true,
  loadingUser: false,
  user: {},
  loggedIn: false,
  isMobileApp: null,
  devicePlatforms: [],
  scanningQRCode: false,
  companyInfo: {}, // settings
  settings: {},

  // project
  allProjects: [],
  projectLobbyDesigns: [],
  projectExternalWallDesigns: [],
  projectFileTypes: [],
  projectRoles: [],
  loadingProjects: true,

  // booking
  bookings: [],
  loadingBookings: true,

  // user data
  userLocations: [],
  loadingUserLocations: true,

  // location
  allLocations: [],
  allLocationFloors: [],

  // notifications
  allNotifications: [],
  loadingNotifications: true,

  surveyData: [], // mainly the questions & translations
  qualifications: [],
  bookingServices: [],
  bookingTimeSlots: ["09:00 - 13:00", "14:00 - 18:00"],
}