import { alertController, isPlatform, loadingController, modalController, toastController } from '@ionic/vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';

import LoginModal from '@/components/auth/LoginModal.vue';
import ImageModal from '@/components/modals/ImageModal.vue';
import ScaffoldReportModal from '@/components/dashboards/ScaffoldReportModal.vue';

export function utils() {
  const { t, locale } = useI18n();
  const PROJECT_STATUSES = {
    pending: '待開工',
    inProgress: '工程中',
    completed: '已完工',
    cancelled: '已取消',
  }
  const BOOKING_STATUSES = {
    pending: 'pending',
    visitConfirmed: 'visitConfirmed',
    visited: 'visited',
    quoted: 'quoted', // quotation sent
    quoteConfirmed: 'quoteConfirmed', // quotation confirmed by clients
    workConfirmed: 'workConfirmed',
    ongoing: 'ongoing', // work in-progress
    workDone: 'workDone', // completed work
    pendingPayment: 'pendingPayment',
    paid: 'paid',
    complete: 'completed',
    cancel: 'cancelled',
    fail: 'failed',
  }
  const downloadBookingFile = async (booking: any, docType = 'quotation') => {
    const url = docType == 'quotation' ? booking.quotationFileLink : booking.receiptFileLink;
    await Browser.open({ url: url.replace('&export=download', '') });
    /*
    if (isPlatform('hybrid')) { // android / ios
      const fileTransfer = new window["FileTransfer"]();
      await new Promise((resolve) => {
        fileTransfer.download(
          encodeURI(url),
          "cdvfile://localhost/persistent/baily/",
          (entry: any) => {
            window["cordova"].plugins.fileOpener2.open(entry.toURL(), "application/pdf")
            resolve(entry.toURL());
          },
          (error: any) => { resolve(error) },
          false,
          {
            headers: {
              "Cache-Control": "no-cache, no-store, must-revalidate",
              "Pragma": "no-cache",
              "Expires": 0
            }
          }
        );
      });
    } else { // web
      await Browser.open({ url: url.replace('&export=download', '') });
    }
    */
  }
  const sleep = (s: any) => {
    return new Promise((resolve) => {
      setTimeout(resolve, s * 1000);
    });
  };
  const downloadFile = async (url: any) => {
    await Browser.open({ url: url.replace('&export=download', '') });
    /*
    if (isPlatform('hybrid')) { // android / ios
      const fileTransfer = new window["FileTransfer"]();
      await new Promise((resolve) => {
        fileTransfer.download(
          encodeURI(url),
          "cdvfile://localhost/persistent/baily/",
          (entry: any) => {
            window["cordova"].plugins.fileOpener2.open(entry.toURL(), "application/pdf")
            resolve(entry.toURL());
          },
          (error: any) => { resolve(error) },
          false,
          {
            headers: {
              "Cache-Control": "no-cache, no-store, must-revalidate",
              "Pragma": "no-cache",
              "Expires": 0
            }
          }
        );
      });
    } else { // web
      await Browser.open({ url: url.replace('&export=download', '') });
    }
    */
  }
  const openModal = async (component: any, componentProps: any, backdropDismiss = true, cssClass = '', sheetModal = false,
                            initialBreakpoint = 1, showHandle = false) => {
    const modal = await modalController.create({
      ...(sheetModal ? {
        breakpoints: [...new Set([0, initialBreakpoint, 1])],
        initialBreakpoint,
        handle: showHandle,
      } : {}),
      cssClass,
      backdropDismiss,
      component,
      componentProps,
    });
    await modal.present();
    return modal;
  }
  const getProxyImgLink = (imgLink: any) => {
    if (imgLink) {
      if (imgLink.includes("imagedelivery.net")) return imgLink; // CloudFlare image no need proxy
      return imgLink.startsWith("http") ? `https://cms.signals.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink;
    }
    return imgLink;
  }
  const addResizeUrlParams = (photoLink: any, imageWidth = 300, returnProxyImgLink = true) => {
    if (photoLink && photoLink.includes("http")) {
      try {
        const url = new URL(photoLink);
        url.searchParams.append('resizeImage', 'true');
        url.searchParams.append('imageWidth', imageWidth.toString());
        return returnProxyImgLink ? getProxyImgLink(url.toString()) : url.toString();
      } catch (e) {
        console.error(e);
        return photoLink;
      }
    }
    return photoLink;
  }

  const toCamel = (str) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };
  const convertKeysToCamelCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = convertKeysToCamelCase(value)
          }
          return value
      })
    } else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          if (origKey === "_RowNumber") { // skip AppSheet row number property
            newO[origKey] = o[origKey];
          } else {
            newKey = toCamel(origKey);
            value = o[origKey]
            if (value instanceof Array || (value && value.constructor === Object)) {
              value = convertKeysToCamelCase(value)
            }
            newO[newKey] = value
          }
        }
      }
    }
    return newO
  };

  const getHTMLImg = async (imgLink: any) => {
    return new Promise((resolve: any) => {
      const IMG_RETRY_MS = 3000; // retry downloading images every X ms on failure
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        resolve({ img, aspectRatio: width/height, width, height });
      }
      img.onerror = () => { setTimeout(() => img.src = imgLink, IMG_RETRY_MS); }
      img.crossOrigin = 'Anonymous';
      img.src = imgLink;
    })
  }
  const toNum = (photoCode) => (photoCode ? Number(photoCode.toString().match(/\d/g).join("")) : null);
  
  return {
    openLoginModal: async () => (await openModal(LoginModal, {}, true, 'login-modal')),
    toNum,
    getHTMLImg,
    convertKeysToCamelCase,
    tStr: (chi, eng) => (locale.value == 'zh' ? chi : eng),
    getLocalizedStr: (dataObj: any, keyChi: any, keyEn: any) => {
      return dataObj ? (locale.value == 'zh' ? dataObj[keyChi] : (dataObj[keyEn] || dataObj[keyChi])) : "";
    },
    focusKeywordSearchbar: () => {
      setTimeout(() => {
        const el: any = document.querySelector('#keyword-searchbar');
        el.setFocus();
      }, 100)
    },
    getBase64FromUrl: async (url, noCache  = false) => {
      const imgUrl = noCache ? `${url}&ts=${new Date().valueOf()}` : url;
      //const options: any = noCache ? { cache: "no-store" } : {};
      //const data = await fetch(getProxyImgLink(imgUrl));
      const data = await fetch(imgUrl);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
          const base64data = reader.result;   
          resolve(base64data);
        }
      });
    },

    isNativeApp: () => (Capacitor.isNativePlatform()),
    isAndroid: () => (isPlatform('android')),
    isMobileWebApp: () => (isPlatform('mobileweb') && (isPlatform('ios') || isPlatform('android'))),
    iPhoneNativeApp: () => (isPlatform('ios') && !isPlatform('mobileweb')),

    uniqueId: () => Math.random().toString(36).slice(-8),
    sleep,
    getProxyImgLink, addResizeUrlParams,
    openModal,
    closeModal: async (data: any = {}) => (await modalController.dismiss(data)),
    openImageModal: async (imageLink: any, caption: any) => (await openModal(ImageModal, { imageLink, caption }, true, "image-modal")),
    openScaffoldReportModal: async (props) => (await openModal(ScaffoldReportModal, props)),

    PROJECT_STATUSES,
    BOOKING_STATUSES,
    formatDate: (date: any, format = 'YYYY/MM/DD HH:mm') => moment(new Date(date)).format(format),
    getBookingStatusColor: (status: string) => {
      if ([BOOKING_STATUSES.fail, BOOKING_STATUSES.cancel].includes(status)) return 'medium';
      if (status == BOOKING_STATUSES.pending) return 'warning';
      if (status == BOOKING_STATUSES.ongoing) return 'primary';
      if (status == BOOKING_STATUSES.complete) return 'success';
      return 'tertiary';
    },
    downloadFile,
    downloadBookingFile,
    isBookingPendingDeposit: (booking: any) => {
      return booking.status == BOOKING_STATUSES.quoted;
    },
    reloadApp: () => {
      SplashScreen.show();
      window.location.reload();
    },
    getProjectDisplayProgress: (progress: any) => {
      return progress == '' ? '' : ` ${Math.min(progress*100, 100).toFixed(0)}%`;
    },
    getProjectStatusColor: (status: string) => {
      if ([PROJECT_STATUSES.cancelled, PROJECT_STATUSES.pending].includes(status)) return 'medium';
      if (status == PROJECT_STATUSES.inProgress) return 'primary';
      if (status == PROJECT_STATUSES.completed) return 'success';
      return 'light';
    },
    numberWithCommas: (x: any) => {
      return (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getRelativeDate: (date: any) => {
      return date ? moment(new Date(date)).fromNow() : "-";
    },
    formatDateString: (d: any) => {
      return d ? moment(new Date(d)).format('YYYY/MM/DD') : "";
    },
    distanceBetweenCoordinates: (lat1: any, lon1: any, lat2: any, lon2: any) => {
      const p = 0.017453292519943295;    // Math.PI / 180
      const c = Math.cos;
      const a = 0.5 - c((lat2 - lat1) * p)/2 + 
              c(lat1 * p) * c(lat2 * p) * 
              (1 - c((lon2 - lon1) * p))/2;

      return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    },
    presentPrompt: async (header: any = "", message: any = "", callback: any, cssClass = "", cancelCallback: any = null) => {
      const alert = await alertController.create({
        header,
        message,
        cssClass,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: cancelCallback,
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    },
    presentAlert: async (msg: string, hideHeader = false) => {
      const obj: any = {
        header: t('errorHeader'),
        message: msg,
        buttons: ['OK']
      }
      if (hideHeader) delete obj.header;
      const alert = await alertController.create(obj);
      return await alert.present();
    },
    presentToast: async(msg: string, duration = 3000, position: any = 'bottom') => {
      const toast = await toastController.create({
        message: msg,
        duration,
        position,
      });
      toast.present();
    },
    presentVerifyCodeInputAlert: async (phone: any, callback: any) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                callback(value.verificationCode);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    }
  }
}