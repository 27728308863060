import { computed, onBeforeUnmount, onDeactivated } from 'vue';
import {
  BarcodeScanner,
  BarcodeFormat,
  LensFacing,
} from '@capacitor-mlkit/barcode-scanning';

import { alertController, modalController, useBackButton } from '@ionic/vue';

import { useStore } from 'vuex';

import UserLocationFormModal from '@/components/modals/UserLocationFormModal.vue';
import { useI18n } from 'vue-i18n';

export function useQRCodeScanner() {
  const store = useStore();
  const { t } = useI18n();

  const devicePlatforms = computed(() => store.state.devicePlatforms);
  const isMobileApp = computed(() => store.state.isMobileApp);
  const scanningQRCode = computed(() => store.state.scanningQRCode);

  const startScanQRCode = async () => {
    await BarcodeScanner.requestPermissions();

    document.querySelector('body')?.classList.add('scanner-active');

    store.commit('setScanningQRCode', true);

    const { barcodes } = await BarcodeScanner.scan({ formats: [BarcodeFormat.QrCode] }); // start scanning and wait for a result

    store.commit('setScanningQRCode', false);
    
    // if the result has content
    if (barcodes && barcodes.length > 0) {
      return barcodes[0].displayValue.split("p/").pop();
    }
    return null;
    
  }
/*
  const startScanQRCode = async () => {
    // check or request permission
    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.granted) {
      document.querySelector('body')?.classList.add('scanner-active');

      store.commit('setScanningQRCode', true);
      
      // the user granted permission
      BarcodeScanner.hideBackground();

      const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

      store.commit('setScanningQRCode', false);
      
      // if the result has content
      if (result.hasContent && result.content) {
        return result.content.split("p/").pop();
      }
      return null;
    }
    else if (status.denied) {
      // the user denied permission for good
      // redirect user to app settings if they want to grant it anyway
      const c = confirm( t('enablePermissionInAppSettings') );
      if (c) {
        BarcodeScanner.openAppSettings();
      }
    }
  }
    */
  const stopScan = async () => {
    document.querySelector('body')?.classList.remove('scanner-active');
    store.commit('setScanningQRCode', false);
    //BarcodeScanner.showBackground();
    //BarcodeScanner.stopScan();
    await BarcodeScanner.removeAllListeners();
    await BarcodeScanner.stopScan();
  };
  onDeactivated(stopScan);
  onBeforeUnmount(stopScan);
  useBackButton(999, (processNextHandler) => {
    if (scanningQRCode.value == true) stopScan(); // close camera preview
    else processNextHandler(); // normal back button event
  });

  const openUserLocationModal = async (editingUserLocation: any = null, projectId: any = null) => {
    const modal = await modalController.create({
      component: UserLocationFormModal,
      componentProps: { editingUserLocation, projectId },
    });
    modal.onDidDismiss().then(({ data }) => {
      if (data && data.updatedUserLocation) {
        if (data.isLocationDeleted) { // user location deleted
          store.commit('deleteUserLocation', data.updatedUserLocation);
        } else { // updated user location
          store.commit('upsertUserLocation', data.updatedUserLocation);
        }
      }
    })
    return modal.present();
  }

  const startScanLocationQRCode = async () => {
    if (isMobileApp.value == true) {
      const projectId: any = await startScanQRCode();
      if (projectId != null) {
        openUserLocationModal(null, projectId);
      }
    } else {
      if (devicePlatforms.value.includes('mobileweb')) {
        //await Browser.open({ url: companyInfo.value.appUrl });
      //} else {
        const obj: any = {
          header: t('errorHeader'),
          message: t('functionOnlyAvailableOnMobileApp'),
          buttons: ['OK']
        }
        const alert = await alertController.create(obj);
        await alert.present();
      }
    }
  }

  return {
    scanningQRCode,
    startScanQRCode, stopScan,
    openUserLocationModal, startScanLocationQRCode, 
  };
}