
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect, computed, onMounted } from 'vue';

// icons
import { close, mail, person, call, key, location, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, modalController, loadingController,
        IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonSegment, IonSegmentButton,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonNote, IonListHeader, IonList,
        alertController, } from '@ionic/vue';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { Geolocation } from '@capacitor/geolocation';

// API services
import LocationService from '@/services/LocationService';

export default defineComponent({
  name: 'UserLocationFormModal',
  props: {
    editingUserLocation: null,
    projectId: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonSegment, IonSegmentButton,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonNote, IonListHeader, IonList, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();

    const userLoggedIn = computed(() => store.state.loggedIn);
    const userLocation = reactive({
      locationId: null,
      floor: "",
      unit: "",
      role: "住戶",
      distanceToLocation: -1, // distance between user's location & project location
    });
    const relatedLocation = ref<any>(null);
    const isEditing = ref(false);
    const loading = computed(() => store.state.loadingAppPublicData);
    const floors = computed(() => store.getters.getFloorsByLocationId(userLocation.locationId));
    const flats = ref<any>([]);

    // methods or filters
    const { t } = useI18n();
    const { formatDate, distanceBetweenCoordinates, presentToast, } = utils();

    const closeModal = async (updatedUserLocation: any = null, isLocationDeleted = false) => {
      await modalController.dismiss({ updatedUserLocation, isLocationDeleted });
    };
    const updateUserLocation = async() => {
      if (userLocation.locationId) {
        const loading = await loadingController.create({});
        await loading.present();
        const res = await LocationService.updateUserLocation(userLocation);
        loading.dismiss();
        presentToast( t('successUpdateUserLocation'), 3000, 'top' );
        closeModal(res);
      } else {
        presentToast( t('selectBuildingOrLocation'), 3000, 'top' );
      }
    }
    const deleteUserLocation = async() => {
      const alert = await alertController.create({
        header: t('confirmDelete'),
        message: t('confirmDeleteUserLocation'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: t('deleteUserLocation'),
            handler: async () => {
              const loading = await loadingController.create({});
              await loading.present();
              const res = await LocationService.deleteUserLocation(userLocation.locationId);
              loading.dismiss();
              presentToast( t('successDeleteUserLocation'), 3000, 'top' );
              closeModal(res, true);
            },
          },
        ],
      });
      return alert.present();
    }
    const getLocationOptions = (locations: any) => {
      return locations.map((l: any) => {
        return { value: l.id, label: `${l.name} [${l.address}]` }
      });
    }
    const presentProjectNotFoundAlert = async() => {
      const alert = await alertController.create({
        header: t('projectNotFound'),
        buttons: [
          {
            text: t('back'),
            handler: () => { closeModal() },
          },
        ],
      });
      return alert.present();
    };
    const onFloorChanged = (selectedFloor: any) => {
      if (selectedFloor && floors.value.length > 0) {
        const floor = floors.value.find((f: any) => f.floor == selectedFloor) || { flats: [] };
        flats.value = floor.flats;
        if (!floor.flats.includes(userLocation.unit)) userLocation.unit = ""; // flat not exist in new floor
      } else { // empty floor
        flats.value = [];
      }
    }

    onMounted(() => {
      const { editingUserLocation, projectId, } = props;
      if (editingUserLocation) { // editing user location
        isEditing.value = true;
        const { locationId, floor, unit, role } = props.editingUserLocation;
        userLocation.locationId = locationId;
        if (floor) userLocation.floor = floor;
        if (unit) userLocation.unit = unit;
        if (role) userLocation.role = role;
        relatedLocation.value = store.getters.getLocationById(locationId);
      }
      if (projectId) {
        const project = store.getters.getProjectById(projectId);
        if (project.id) { // project exists
          userLocation.locationId = project.locationId;
          relatedLocation.value = store.getters.getLocationById(userLocation.locationId);

          // check if user is near the project location
          Geolocation.getCurrentPosition().then(coordinates => {
            const { latitude, longitude } = coordinates.coords;
            userLocation.distanceToLocation = distanceBetweenCoordinates(latitude, longitude, project.latitude, project.longitude);
          });
        } else {
          presentProjectNotFoundAlert();
        }
      }
      onFloorChanged(userLocation.floor);
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, mail, call, person, key, location,

      // variables
      userLocation, isEditing, relatedLocation,
      loading, flats, floors,
      userLoggedIn,

      // methods
      t, formatDate, closeModal,
      updateUserLocation, deleteUserLocation, getLocationOptions,
      onFloorChanged,
    }
  }
});
