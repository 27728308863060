
// Vue reactivity
import { defineComponent, reactive } from 'vue';

// icons
import { arrowForward, } from 'ionicons/icons';

// components
import { IonContent, IonGrid, IonRow, IonCol, IonItem, IonButton, IonLabel, IonInput,
        loadingController, modalController, alertController, } from "@ionic/vue";

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

// services
import UserService from '@/services/UserService';
        
export default defineComponent({
  name: 'CompleteProfileModal',
  props: [],
  components: { IonContent, IonGrid, IonRow, IonCol, IonItem, IonButton, IonLabel, IonInput, },
  setup() {
    const user = reactive({
      firstName: "", // first name
      email: "",
    });

    const store = useStore();
    const { t, locale, } = useI18n();
    const { presentAlert, } = utils();

    return {
      // icons
      arrowForward,

      // variables
      locale,
      user,

      // methods
      t,
      updateUser: async () => {
        const loading = await loadingController.create({});
        await loading.present();
        await UserService.updateLoggedInUser(user, user);
        store.commit('updateUser', user);
        store.dispatch('getUserData', {}); // get latest user data (including coupons from invitation codes)
        loading.dismiss();
        await modalController.dismiss();
        try {
          await modalController.dismiss(); // Login Modal
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
});
