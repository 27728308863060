import { createStore, createLogger } from 'vuex';

import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default createStore({
  state: { ...state },
  getters: { ...getters },
  actions: { ...actions },
  mutations: { ...mutations },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})