import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_logo_img = _resolveComponent("logo-img")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, {
        class: "ion-no-padding",
        fixed: ""
      }, {
        default: _withCtx(() => [
          ($props.logoOnly)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                color: "primary"
              }, {
                default: _withCtx(() => [
                  ($props.parentPath)
                    ? (_openBlock(), _createBlock(_component_ion_buttons, {
                        key: 0,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_back_button, {
                            class: "back-icon",
                            "default-href": $props.parentPath
                          }, null, 8, ["default-href"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_logo_img)
                    ]),
                    _: 1
                  }),
                  ($props.slotType != 'toolbar')
                    ? _renderSlot(_ctx.$slots, "default", { key: 1 })
                    : _createCommentVNode("", true)
                ]),
                _: 3
              }))
            : ($props.showCloseModal)
              ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 1 }, {
                  default: _withCtx(() => [
                    ($props.useTopRightCloseBtn)
                      ? (_openBlock(), _createBlock(_component_ion_buttons, {
                          key: 0,
                          slot: "end"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              slot: "icon-only",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeModal()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: $setup.close }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_buttons, {
                          key: 1,
                          slot: "start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              slot: "icon-only",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.closeModal()))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: $setup.arrowBack }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })),
                    ($props.title)
                      ? (_openBlock(), _createBlock(_component_ion_title, {
                          key: 2,
                          class: "ion-text-left",
                          style: {"padding-left":"0"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap valign ion-justify-content-center" }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, _toDisplayString($props.title), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_title, {
                          key: 3,
                          class: "ion-text-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_logo_img)
                          ]),
                          _: 1
                        })),
                    ($props.slotType != 'toolbar')
                      ? _renderSlot(_ctx.$slots, "default", { key: 4 })
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }))
              : ($props.titleOnly)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($props.title), 1)
                        ]),
                        _: 1
                      }),
                      ($props.slotType != 'toolbar')
                        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  }))
                : ($props.parentPath)
                  ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 3 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_buttons, { slot: "start" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_back_button, {
                              class: "back-icon",
                              "default-href": $props.parentPath
                            }, null, 8, ["default-href"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_title, { class: "ion-text-left" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap valign ion-justify-content-center" }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, _toDisplayString($props.title || $setup.t('back')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        ($props.slotType != 'toolbar')
                          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                          : _createCommentVNode("", true)
                      ]),
                      _: 3
                    }))
                  : (_openBlock(), _createBlock(_component_ion_toolbar, { key: 4 }, {
                      default: _withCtx(() => [
                        (!$props.hideLogo)
                          ? (_openBlock(), _createBlock(_component_ion_title, {
                              key: 0,
                              class: "ion-text-start"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_logo_img)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })),
          ($props.slotType == 'toolbar')
            ? _renderSlot(_ctx.$slots, "default", { key: 5 })
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}