import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

export default {
  /**
   * Report (mainly for data querying)
   */
  async queryData(payload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('report-queryData')(payload)).data;
  },
}