import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c4ee1a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding-vertical ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.t('completeRegistration')), 1)
      ]),
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "10" }, {
                default: _withCtx(() => [
                  _createElementVNode("form", null, [
                    _createVNode(_component_ion_input, {
                      fill: "outline",
                      label: _ctx.t('nickname'),
                      "label-placement": "floating",
                      type: "text",
                      modelValue: _ctx.user.firstName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.firstName) = $event))
                    }, null, 8, ["label", "modelValue"]),
                    _createVNode(_component_ion_input, {
                      fill: "outline",
                      label: _ctx.t('RegisterPage.email'),
                      "label-placement": "floating",
                      inputmode: "email",
                      type: "text",
                      modelValue: _ctx.user.email,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event))
                    }, null, 8, ["label", "modelValue"]),
                    _createVNode(_component_ion_button, {
                      shape: "round",
                      expand: "block",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUser()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('finish')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}