// customized getters

export const getSurveyByKey = (state) => (key) => {
  return state.surveyData.find(s => s.key == key) || {};
}

/**
 * BOOKING
 */
export const getBookingById = (state) => (id) => {
  return state.bookings.find(booking => booking.id == id) || {};
}

// HOME
export const groupedQualifications = (state) => {
  const groupedObj = {};
  state.qualifications.forEach(q => (groupedObj[q.type] = groupedObj[q.type] || []).push(q));
  return groupedObj;
}

// PROJECTS
export const getLobbyDesignsByProjectId = (state) => (projectId) => {
  return state.projectLobbyDesigns.filter(d => d.projectId == projectId);
}
export const getExternalWallDesignsByProjectId = (state) => (projectId) => {
  return state.projectExternalWallDesigns.filter(d => d.projectId == projectId);
}
export const getProjectById = (state) => (id) => {
 const project = state.allProjects.find(p => p.id == id) || {};
 if (project.relatedToUser) {
   project.userLocation = state.userLocations.find(ul => project.locationId == ul.locationId);
 }
 return project;
}
export const getProjectsByType = (state) => (type) => (state.allProjects.filter(p => p.type?.includes(type)));
export const getProjectsByStatus = (state) => (status) => (state.allProjects.filter(project => project.status == status));
export const latestProjects = (state) => (state.allProjects.slice(0, 5)); // return latest 5 products
export const sortedProjects = (state) => {
  return [
    ...state.allProjects.filter(p => p.userRoles.length > 0).sort((a, b) => (new Date(a.lastAccessTime) < new Date(b.lastAccessTime) ? 1 : -1)),
    ...state.allProjects.filter(p => p.userRoles.length == 0 && p.relatedToUser),
    ...state.allProjects.filter(p => p.userRoles.length == 0 && !p.relatedToUser),
  ];
}
export const projectsRelatedToUsers = (state) => (state.allProjects.filter(p => p.relatedToUser));
export const userInvolvingProjects = (state) => (state.allProjects.filter(p => p.userRoles.length > 0));
export const allProjectTypes = (state) => {
  const tmpObj = {};
  state.allProjects.forEach(p => {
    if (p.type) {
      for (const type of p.type.split(" , ")) tmpObj[type] = (tmpObj[type] || 0) + 1
    }
  });
  return Object.keys(tmpObj).map(type => {
    return { name: type, numOfProjects: tmpObj[type] };
  });
}

// NOTIFICATIONS
export const getNotificationById = (state, getters) => (id) => {
  return state.user.isInternal ? state.allNotifications.find(n => n.id == id) || {} // internal user can access any notification
          : getters.notificationsRelatedToUser.find(n => n.id == id) || {};
}

export const projectsWithNotifications = (state, getters) => {
  const tmpObj = {};
  getters.notificationsRelatedToUser.forEach(n => tmpObj[n.projectTitle] = (tmpObj[n.projectTitle] || 0) + 1);
  return Object.keys(tmpObj).map(projectTitle => {
    return { title: projectTitle, numOfNotifications: tmpObj[projectTitle] };
  });
}

export const notificationsRelatedToUser = (state, getters) => {
  const notifications = state.allNotifications.filter(notification => {
    const project = getters.getProjectById(notification.projectId);
    return project.relatedToUser || project.subscribedByUser;
  });
  const otherNotifications = [];
  const unsubmittedNotifications = notifications.filter(n => { // survey not submitted
    if (n.survey) {
      const userLocation = getters.getUserLocationByProjectId(n.projectId);
      if (userLocation) {
        n.userNotSubmittedSurvey = userLocation[`${n.survey} q1Ans`] ? false : true;
        if (n.userNotSubmittedSurvey) return true; // answer is empty for the first question
      }
    }
    otherNotifications.push(n);
    return false;
  })
  return [
    ...unsubmittedNotifications,
    ...otherNotifications,
  ];
}
export const numOfNewNotifications = (state, getters) => {
  return getters.notificationsRelatedToUser.filter(notification => {
    return notification.userNotSubmittedSurvey || (state.user.lastCheckNotificationsAt ? 
          new Date(notification.scheduledToSendAt) > new Date(state.user.lastCheckNotificationsAt) : true);
  }).length;
}

// LOCATIONS
export const getLocationById = (state) => (locationId) => {
  return state.allLocations.find(l => l.id == locationId);
}
export const getFloorsByLocationId = (state) => (locationId) => {
  return state.allLocationFloors.filter(floor => floor.locationId == locationId);
}
export const getLocationIdFromAddress = (state) => (address) => {
  const ul = state.userLocations.find(ul => address.includes(ul.locationName));
  return ul ? ul.locationId : "";
}
export const getUserLocationByLocationId = (state) => (locationId) => {
  return state.userLocations.find(ul => ul.locationId == locationId);
}
export const getUserLocationByProjectId = (state, getters) => (projectId) => {
  const project = getters.getProjectById(projectId);
  return project.userLocation;
}