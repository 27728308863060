import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55a81984"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"white-space":"pre-wrap"} }
const _hoisted_2 = { class: "swiper-zoom-container" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        (_ctx.checkLocationId)
          ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      slot: "icon-only",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.caption)
                  ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h1", null, _toDisplayString(_ctx.caption), 1),
                            _createElementVNode("h2", null, _toDisplayString(_ctx.step), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.canEdit)
                  ? (_openBlock(), _createBlock(_component_ion_buttons, {
                      key: 1,
                      slot: "end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          fill: "solid",
                          color: "success",
                          size: "large",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCheckLocationPhoto(_ctx.checkLocationId, _ctx.step)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('change')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          fill: "solid",
                          color: "danger",
                          size: "large",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteCheckLocationPhoto(_ctx.checkLocationId, _ctx.step)))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('delete')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_ion_toolbar, { key: 1 }, {
              default: _withCtx(() => [
                (_ctx.caption)
                  ? (_openBlock(), _createBlock(_component_ion_title, {
                      key: 0,
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.caption), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      slot: "icon-only",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModal()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_ion_skeleton_text, {
          animated: true,
          style: {"width":"100%","height":"100vh"}
        }, null, 512), [
          [_vShow, !_ctx.imgLoaded]
        ]),
        _createVNode(_component_swiper, {
          modules: _ctx.modules,
          zoom: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_swiper_slide, null, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "wrapper",
                  style: _normalizeStyle({ background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${_ctx.updatedImgLink || _ctx.imageLink})` })
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                      class: "main-img",
                      src: (_ctx.updatedImgLink || _ctx.imageLink).replace('/public', '/original'),
                      onLoad: _cache[4] || (_cache[4] = ($event: any) => (_ctx.imgLoaded = true))
                    }, null, 40, _hoisted_3)
                  ])
                ], 4)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modules"])
      ]),
      _: 1
    })
  ], 64))
}