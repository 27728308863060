import { createApp } from 'vue'
import AppComponent from '@/App.vue'
import { routes } from '@/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import './registerServiceWorker';

import { IonicVue, getPlatforms, alertController, } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/global.css';
import '@/theme/custom.css';

/* Firebase */
import { auth } from '@/auth';

/* vue-i18n */
import { createI18n, useI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import store from '@/store'

// tsparticles
import Particles from "vue3-particles";

/* Supabase (mainly for Realtime) */
import { createClient } from '@supabase/supabase-js'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SplashScreen } from '@capacitor/splash-screen';
import { Network } from '@capacitor/network';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import UserService from '@/services/UserService';
import CommonService from '@/services/CommonService';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages,
  silentTranslationWarn: true,
  legacy: false,
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(AppComponent)
  .use(store)
  .use(i18n)
  .use(IonicVue, { mode: 'md', innerHTMLTemplatesEnabled: true })
  .use(router)
  .use(Particles)

const supabase = createClient(
  "https://ehkxchejuuwfqeeottvc.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVoa3hjaGVqdXV3ZnFlZW90dHZjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzA4NDE1MDgsImV4cCI6MTk4NjQxNzUwOH0.abnM7_W2Fv8vN09v4YvCtB2K-YkhQHWQbLIspy_QDNk"
);
app.provide('supabase', supabase)

auth.languageCode = 'zh-HK';

router.isReady().then(async () => {
  i18n.global.locale.value = navigator.language.split('-')[0].toLowerCase() == "en" ? "en" : "zh";
  document.body.classList.toggle('dark', true);

  let status = await Network.getStatus();
  if (!status.connected) SplashScreen.hide();
  while (!status.connected) {
    const alert = await alertController.create({
      backdropDismiss: false,
      message: i18n.global.locale.value == 'zh' ? '手機尚未連接至網絡，請重試。' : 'Seems like there is a network connection problem. Please try again',
      buttons: [
        {
          text: i18n.global.locale.value == 'zh' ? '重試' : 'Retry',
          handler: async () => {
            status = await Network.getStatus();
          },
        },
      ],
    });
    await alert.present();
    await alert.onDidDismiss();
  }
  
  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getPublicData().then(res => {
    store.commit('receiveAppPublicData', res);
    SplashScreen.hide();
  });
  store.commit('receiveDevicePlatforms', getPlatforms());
  //store.dispatch('getAllProjects');

  /* Detect user login / logout */
  auth.onAuthStateChanged((user: any) => {
    if (user) { // logged in
      if (window["plugins"]) {
        //window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
        window["plugins"].OneSignal.login(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const { query, path } = router.currentRoute.value;
      const redirectPath: any = query.redirectPath;
      if (redirectPath && !config.authPages.includes(redirectPath)) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (path.includes('callback') || config.authPages.includes(path)) {
        router.replace('/home'); // go to home page if the user in auth pages
      }
      store.dispatch('getUserData', { firebaseUser: user, i18n });

      // notifications (TODO: check & get only notifications related to the users)
      CommonService.getAllNotifications().then(res => {
        store.commit('receiveNotifications', res);
      });
    } else { // logged out
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId("");
      }
      store.dispatch('resetUserData');
      const { path: currentPath, name: currentPageName } = router.currentRoute.value;
      if (config.memberPages.includes(currentPath) || config.memberPageNames.includes((currentPageName || "").toString())) {
        router.replace({ path: '/login', query: { redirectPath: currentPath } });
      }
    }
  });
  app.mount('#app');
  
  /* Check App state & events (e.g. enter App foreground & refresh data) */
  if (Capacitor.isNativePlatform()) {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        CommonService.getPublicData().then(res => {
          store.commit('receiveAppPublicData', res);
        });
        if (auth.currentUser) { // logged in users
          store.dispatch('getUserData', { disableLoadingBooking: true });
        }
        //store.dispatch('getAllProjects');
      }
    });
  }

  /* Check App Deeplink & do redirect */
  App.addListener('appUrlOpen', (data) => {
    const slug = data.url.split(/\.app|\.hk|\.com/).pop();
    if (slug) {
      router.replace(slug);
    }
  });

  /**
   * OneSignal
   **/
  window["plugins"].OneSignal.initialize(config.OneSignal.appId);
  window["plugins"].OneSignal.Notifications.addEventListener("click", (event: any) => {
    const { additionalData } = (event.notification.payload || {});
    if (additionalData) {
      const { app_notification_id: notificationId, app_booking_id: bookingId, } = additionalData;
      if (bookingId) {
        router.replace(`/bookings/${bookingId}`);
      }
      else if (notificationId) {
        CommonService.getAllNotifications().then(res => {
          store.commit('receiveNotifications', res); // refresh list of notifications
        })
        router.replace(`/notifications/${notificationId}`);
      }
    }
  });
  window["plugins"].OneSignal.Notifications.requestPermission(true).then((accepted) => {
    console.log("User accepted notifications: " + accepted);
  });
  

  /*
  window["plugins"].OneSignal.setAppId(config.OneSignal.appId);
  window["plugins"].OneSignal.setNotificationOpenedHandler((jsonData) => {
    // triggerred on tap notifications from devices
    const { additionalData } = (jsonData.notification.payload || {});
    if (additionalData) {
      const { app_notification_id: notificationId, app_booking_id: bookingId, } = additionalData;
      if (bookingId) {
        router.replace(`/bookings/${bookingId}`);
      }
      else if (notificationId) {
        CommonService.getAllNotifications().then(res => {
          store.commit('receiveNotifications', res); // refresh list of notifications
        })
        router.replace(`/notifications/${notificationId}`);
      }
    }
  });

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse((accepted: any) => {
    console.log("User accepted notifications: " + accepted);
  });
  */
});

router.beforeEach((to, from) => {
  if (auth.currentUser == null) { // guests (not logged in)
    if (config.memberPages.includes(to.path) || config.memberPageNames.includes(to.name?.toString())) {
    //if (!config.authPages.includes(to.path)) { // force go to login page
      const redirectPath = to.path == '/checkout' ? '/bookings' : to.path;
      return { path: '/login', query: { redirectPath } };
    }
  } else { // logged in users
    if (to.path == '/notifications') {
      // update user last click notification tab time
      store.dispatch('updateUserLastCheckNotificationTime');
    }
  }
})