const updateBookingFields = (b) => {
  b.clientPhotoLinks = b.clientPhotoLinks ? b.clientPhotoLinks.split(" , ") : [];
  b.beforePhotoLinks = b.beforePhotoLinks ? b.beforePhotoLinks.split(" , ") : [];
  b.afterPhotoLinks = b.afterPhotoLinks ? b.afterPhotoLinks.split(" , ") : [];
}

const handleUserData = (state, user) => {
  const { id: userId, darkTheme, subscribedProjectIds, relatedLocationIds, involvingProjects } = user;
  state.user = user;
  state.loggedIn = (userId ? true : false);
  //document.body.classList.toggle('dark', userId ? darkTheme == true : state.isMobileApp);
  document.body.classList.toggle('dark', true); // default use dark theme
  
  state.allProjects.forEach(project => {
    project.subscribedByUser = subscribedProjectIds?.includes(project.id);
    project.relatedToUser = relatedLocationIds?.includes(project.locationId);
    project.userRoles = involvingProjects?.filter(r => r.projectId == project.id).map(r => r.role) || [];
    project.lastAccessTime = (involvingProjects?.find(r => r.projectId == project.id) || {}).lastAccessTime;
    project.userPermissions = project.userRoles.map(r => r.permissions).join(" , ").split(" , ").filter(p => !!p) || [];
  });
  state.loadingUser = false;
}


export default {
  // Asset
  upsertAssets(state, assets) {
    for (const asset of assets) {
      const project = state.allProjects.find(project => project.id == asset.projectId);
      const existingAsset = project.assets.find(a => a.id == asset.id);
      if (existingAsset) {
        for (const key in asset) {
          existingAsset[key] = asset[key];
        }
      } else {
        project.assets.push(asset);
      }
    }
  },

  // Device
  upsertDevice(state, device) {
    const project = state.allProjects.find(project => project.id == device.projectId);
    const existingDevice = project.devices.find(d => d.id == device.id);
    if (existingDevice) {
      for (const key in device) {
        existingDevice[key] = device[key];
      }
    } else {
      project.devices.push(device);
    }
  },

  // BD Report
  upsertWorkLocation(state, updatedWorkLocation) {
    const project = state.allProjects.find(project => project.id == updatedWorkLocation.projectId);
    const wl = project.workLocations.find(l => l.id == updatedWorkLocation.id);
    if (wl) {
      for (const key in updatedWorkLocation) {
        wl[key] = updatedWorkLocation[key];
      }
    } else {
      project.workLocations.push(updatedWorkLocation);
    }
  },
  deleteWorkLocation(state, { projectId, id, }) {
    const project = state.allProjects.find(project => project.id == projectId);
    const idx = project.workLocations.findIndex(l => l.id == id);
    if (idx !== -1) project.workLocations.splice(idx, 1);
  },
  upsertCheckLocation(state, updatedCheckLocation) {
    const project = state.allProjects.find(project => project.id == updatedCheckLocation.projectId);
    const cl = project.workPhotoRecords.find(l => l.id == updatedCheckLocation.id);
    if (cl) {
      for (const key in updatedCheckLocation) {
        cl[key] = updatedCheckLocation[key];
      }
    } else {
      project.workPhotoRecords.push(updatedCheckLocation);
    }
  },
  batchInsertPlotPoints(state, { points, projectId }) {
    const project = state.allProjects.find(project => project.id == projectId);
    for (const point of points) {
      project.workPhotoRecords.push(point);
    }
  },
  deleteCheckLocation(state, { projectId, id, }) {
    const project = state.allProjects.find(project => project.id == projectId);
    const idx = project.workPhotoRecords.findIndex(l => l.id == id);
    if (idx !== -1) project.workPhotoRecords.splice(idx, 1);
  },

  // Batch Upload Job
  upsertBatchUploadJob(state, updatedObj) {
    const project = state.allProjects.find(project => project.id == updatedObj.projectId);
    const job = project.batchUploadJobs.find(j => j.id == updatedObj.id);
    if (job) {
      for (const key in updatedObj) {
        job[key] = updatedObj[key];
      }
    } else {
      project.batchUploadJobs.push(updatedObj);
    }
  },

  // Project
  upsertProjects(state, data) {
    const { subscribedProjectIds, relatedLocationIds, involvingProjects, } = state.user;
    data.forEach(p => {
      p.subscribedByUser = subscribedProjectIds?.includes(p.id);
      p.relatedToUser = relatedLocationIds?.includes(p.locationId);
      p.userRoles = involvingProjects?.filter(r => r.projectId == p.id).map(r => r.role) || [];
      p.userPermissions = p.userRoles.map(r => r.permissions).join(" , ").split(" , ").filter(p => !!p) || [];
      const idx = state.allProjects.findIndex(project => project.id == p.id);
      if (idx !== -1) {
        state.allProjects[idx] = {
          ...state.allProjects[idx],
          ...p,
        }
      } else {
        state.allProjects.push(p);
      }
    });
    state.loadingProjects = false;
  },

  // App Public Data
  receiveAppPublicData(state, data) {
    const { settings, companyInfo, qualifications, locations, locationFloors, bookingServices, websiteServices, surveyData,
            projectLobbyDesigns, projectExternalWallDesigns, projectFileTypes, projectRoles, } = data;
    if (companyInfo) {
      companyInfo.services = websiteServices;
      state.companyInfo = companyInfo;
      state.bookingTimeSlots = companyInfo.bookingTimeSlots.split(" , ");
    }
    if (settings) state.settings = settings;
    if (qualifications) state.qualifications = qualifications;
    if (surveyData) state.surveyData = surveyData;
    if (bookingServices) state.bookingServices = bookingServices;
    if (locations) state.allLocations = locations;
    if (locationFloors) {
      state.allLocationFloors = locationFloors.map(floor => {
        const splitCode = floor.code.split('_'); // <location_id>_<floor>
        floor.locationId = splitCode[0];
        floor.flats = floor.relatedLocationFlats.split(" , ").map(code => code.split('_')[2]);
        return floor;
      });
    }
    if (projectLobbyDesigns) state.projectLobbyDesigns = projectLobbyDesigns;
    if (projectExternalWallDesigns) state.projectExternalWallDesigns = projectExternalWallDesigns;
    if (projectFileTypes) state.projectFileTypes = projectFileTypes;
    if (projectRoles) state.projectRoles = projectRoles;

    state.loadingAppPublicData = false;
  },

  // GENERAL
  receiveDevicePlatforms(state, platforms) {
    state.devicePlatforms = platforms;
    state.isMobileApp = platforms.includes('ios') || platforms.includes('android') &&
                        !( platforms.includes('desktop') || platforms.includes('mobileweb') ) ;
  },
  receiveNotifications(state, data) {
    data.forEach(n => {
      n.photos = n.photoDescriptions ? n.photoDescriptions.split(" , ").map(pd => {
        const splitPd = pd.split(" | ");
        return { link: splitPd[0], description: splitPd[1] || ""};
      }) : [];
    })
    state.allNotifications = data;
    state.loadingNotifications = false;
  },
  setScanningQRCode(state, isScanning) {
    if (!isScanning || document.body.classList.contains('dark')) {
      document.body.classList.toggle('dark', !isScanning); // disable dark theme to show the camera
    }
    state.scanningQRCode = isScanning;
  },

  // USER
  setLoadingUserData(state, isLoading) {
    state.loadingUser = isLoading;
  },
  updateUser(state, updatedObj) {
    for (const key in updatedObj) state.user[key] = updatedObj[key];
  },
  receiveAppUserData(state, res) {
    const { user, userLocations, } = res;
    if (user) handleUserData(state, user);
    if (userLocations) state.userLocations = userLocations;
  },
  receiveUser(state, user) {
    handleUserData(state, user);
  },

  // LOCATIONS
  upsertUserLocation(state, updatedUserLocation) {
    const idx = state.userLocations.findIndex(ul => ul.locationId == updatedUserLocation.locationId);
    if (idx !== -1) {
      state.userLocations.splice(idx, 1, updatedUserLocation);
    } else {
      state.userLocations.push(updatedUserLocation);
    }
    const relatedProject = state.allProjects.find(project => project.locationId == updatedUserLocation.locationId);
    if (relatedProject) relatedProject.relatedToUser = true;
  },
  deleteUserLocation(state, deletedUserLocation) {
    const idx = state.userLocations.findIndex(ul => ul.locationId == deletedUserLocation.locationId);
    if (idx !== -1) state.userLocations.splice(idx, 1);
    const relatedProject = state.allProjects.find(project => project.locationId == deletedUserLocation.locationId);
    if (relatedProject) {
      relatedProject.subscribedByUser = false;
      relatedProject.relatedToUser = false;
    }
  },

  // BOOKING
  addBooking(state, booking) {
    updateBookingFields(booking);
    state.bookings.push(booking);
    state.loadingBookings = false;
  },
  setLoadingBookings(state, isLoading) {
    state.loadingBookings = isLoading;
  },
  receiveBookings(state, bookings) {
    bookings.forEach(b => {
      updateBookingFields(b);
    })
    state.bookings = bookings;
    state.loadingBookings = false;
  },
}