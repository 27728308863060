
// Vue reactivity
import { defineComponent, ref } from 'vue';

// Swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Zoom } from 'swiper';

// icons
import { close, arrowBack } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonSkeletonText, IonLabel,
        loadingController, } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { Photo, usePhotoGallery } from '@/composables/usePhotoGallery';

// service
import ProjectService from '@/services/ProjectService';

export default defineComponent({
  name: 'ImageModal',
  props: [
    "imageLink", "caption",
    "projectId", "checkLocationId", "step", // for Project Check Location (Floor Plan Point & Photo Step)
    "canEdit",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonSkeletonText, IonLabel,
                Swiper, SwiperSlide, },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { closeModal, presentPrompt, presentToast, } = utils();
    const { takePhoto, } = usePhotoGallery();
    const imgLoaded = ref(false);
    const updatedImgLink = ref("");

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      
      // variables
      imgLoaded,
      modules: [Zoom],
      updatedImgLink, // Project Check Location: replace image

      // method
      t, closeModal,
      updateCheckLocationPhoto: async (id, step) => {
        const photo: Photo = await takePhoto();
        if (photo) {
          const loading = await loadingController.create({});
          await loading.present();
          const newPhotoLink = await ProjectService.updateCheckLocationImage(props.projectId, id, step, photo);
          store.commit('upsertCheckLocation', { id, [`${step}相片`]: newPhotoLink, projectId: props.projectId });
          updatedImgLink.value = newPhotoLink;
          presentToast(t('successUpdatePhoto'), 1000, 'bottom');
          loading.dismiss();
        }
      },
      deleteCheckLocationPhoto: async (id, step) => {
        presentPrompt("", t('confirmDelete'), async () => {
          const loading = await loadingController.create({});
          await loading.present();
          ProjectService.updateCheckLocationImage(props.projectId, id, step, null);
          store.commit('upsertCheckLocation', { id, [`${step}相片`]: "", [`uppy-${step}`]: null, projectId: props.projectId });
          loading.dismiss();
          closeModal();
        });
      }
    }
  }
});
