
// Vue reactivity
import { defineComponent } from 'vue';

// components
import { IonContent, } from '@ionic/vue';
import LoginForm from '@/components/auth/LoginForm.vue';
import PageHeader from '@/components/PageHeader.vue';

// composables
import { useI18n } from 'vue-i18n';
        
export default defineComponent({
  name: 'LoginModal',
  props: [],
  components: { IonContent, LoginForm, PageHeader, },
  setup() {
    const { t, } = useI18n();
    
    return {
      t,
    }
  }
});
