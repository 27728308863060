
import config from '@/config';

export default {
  props: [
    "withText",
  ],
  setup() {
    const { logo, logoWithText } = config;
    return { logo, logoWithText }
  }
}
