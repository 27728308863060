import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { uploadImage } from '@/services/utils';
import { Photo } from '@/composables/usePhotoGallery';

export default {
  // HOME page
  async createNewFeedback(feedback: any, photos: Photo[], project: any = null) {
    const photoLinks = []; // image URL
    if (photos) {
      for (const photo of photos) {
        const link = await uploadImage(photo.base64Data, feedback.name);
        photoLinks.push(link);
      }
    }
    return await firebase.app().functions('asia-east2').httpsCallable('common-createNewFeedback')({ ...feedback, photoLinks, project });
  },

  async getPublicData() {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getPublicData')({})).data;
  },

  async getAllNotifications() {
    return (await firebase.app().functions('asia-east2').httpsCallable('common-getAllNotifications')()).data;
  },

  async createNewSurveyResponse(response: any, photos: Photo[], notification: any) {
    const photoLinks = []; // image URL
    if (photos) {
      for (const photo of photos) {
        const link = await uploadImage(photo.base64Data, `${notification.survey}-${response.name}-p`);
        photoLinks.push(link);
      }
    }
    if (response.signatureDataURL) { // client signed
      const link = await uploadImage(response.signatureDataURL, `${notification.survey}-${response.name}-s`);
      response.clientSignatureLink = link;
    }
    return await firebase.app().functions('asia-east2').httpsCallable('common-createNewSurveyResponse')({ response, photoLinks, notification });
  }
}